cei.shared.monetate = (function () {
    /**
     * @description push array data to monetateQ array
     * @param {array} arr - array to be pushed to monetateQ array
     */
    function pushToMonetate(arr) {
        window.monetateQ.push(arr);
    }
    /**
     * @description add cart rows
     * @param {array} data - array of product number, quantity, unit price, and currency strings
     */
    function addCartRows(data) {
        pushToMonetate(["addCartRows", data]);
    }
    /**
     * @description add categories
     * @param {string[]} data - string array of categories
     */
    function addCategories(data) {
        pushToMonetate(["addCategories", data]);
    }
    /**
     * @description add product details
     * @param {string[]} data - string array of product numbers and skus
     */
    function addProductDetails(data) {
        pushToMonetate(["addProductDetails", data]);
    }
    /**
     * @description add products
     * @param {string[]} data - string array of product numbers
     */
    function addProducts(data) {
        pushToMonetate(["addProducts", data]);
    }
    /**
     * @description add purchase rows
     * @param {array} data - array of purchase id, product number, quantity, unit price, and currency strings
     */
    function addPurchaseRows(data) {
        pushToMonetate(["addPurchaseRows", data]);
    }
    /**
     * @description set page type
     * @param {string} pageType - the page type
     */
    function setPageType(pageType) {
        pushToMonetate(["setPageType", pageType]);
    }
    /**
     * @description send data to monetate
     */
    function trackData() {
        pushToMonetate(["trackData"]);
    }

    /**
     * @description track page type
     * @param {string} pageType - the page type
     */
    function trackPageType(pageType) {
        window.monetateQ = window.monetateQ || [];
        setPageType(pageType);
        trackData();
    }

    return {
        addCartRows: addCartRows,
        addCategories: addCategories,
        addProductDetails: addProductDetails,
        addProducts: addProducts,
        addPurchaseRows: addPurchaseRows,
        setPageType: setPageType,
        trackData: trackData,
        trackPageType: trackPageType
    };

})();