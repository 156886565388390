cei.shared.navigation = (function ($) {
    if ($('#main-nav').length) {
        var brandBackgroundColor;
        var menus = [];
        var navbar = [];
        var navigation = {
            primary: []
        };
        var vm = new Vue({
            el: '#mobile-nav',
            data: {
                brandBackgroundColor: "",
                categoryLink: [],
                destinationName: [],
                destinationUrl: [],
                hasChildren: false,
                isRootNav: true,
                menuId: '#mobile-primary',
                nav: [],
                navBar: [],
                navType: [],
                originName: "",
                originUrl: '#',
                previousCategoryLink: [],
                previousNav: [],
                previousNavType: [],
                targetIndex: []
            },
            mounted: function () {
                this.init();
            },
            methods: {
                closeMobileNav: function () {
                    $('#mobile-nav').hide();
                    $('#mobile-background').hide();
                    $('.navbar-header').find('button').addClass('collapsed');
                    this.resetMenu();
                },
                getPreviousMenu: function (index, link) {
                    var type = this.navType;

                    if (type === 'tertiary') {
                        type = 'secondary';
                        this.navType = type;
                        this.nav = this.previousNav;
                        this.originName = navigation.primary[this.targetIndex].primary;
                        this.originUrl = navigation.primary[this.targetIndex].link;
                        this.setCategoryLink(this.previousCategoryLink);
                        this.switchMenu(type);
                        this.isRootNav = false;
                    } else if (type === 'secondary') {
                        this.resetMenu();
                    }
                },
                hasNestedLinks: function (nav, type) {
                    var child, parent, i, target;

                    if (type === "primary") {
                        child = "secondary";
                    } else {
                        child = "tertiary";
                    }
                    for (i = 0; i < nav.length; i++) {
                        parent = nav[i];
                        if (parent[child].length) {
                            this.nav[i].children = true;
                        } else {
                            this.nav[i].children = false;
                        }
                    }
                    target = nav[0][type];
                },
                init: function () {
                    var activeBrandLink = $('a[data-brand-id="' + brandIdentifier + '"]');

                    this.nav = navigation.primary;
                    this.navBar = navbar;
                    this.brandBackgroundColor = brandBackgroundColor;
                    brandBackgroundColor = activeBrandLink.css("background-color");
                },
                isLink: function (link) {
                    if (link && link.length && link !== "#" && link !== "/views/navigation/allDepartment") {
                        return true;
                    }

                    return false;
                },
                pushToGTM: function (gtmCategory) {
                    if (gtmCategory) {
                        // GTM - GA4
                        cei.shared.tagmanager.addToGA4DataLayer({
                            'event': 'category_selected',
                            'browse_category': gtmCategory
                        });
                    }
                },
                resetMenu: function () {
                    this.nav = navigation.primary;
                    this.originName = "All Departments";
                    this.originUrl = "/views/navigation/allDepartment";
                    this.switchMenu('primary');
                    this.setNav(this.originName, this.originUrl, 'primary');
                    this.isRootNav = true;
                },
                scrollToTop: function () {
                    if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
                        window.scrollBy(0, -50);
                        requestAnimationFrame(this.scrollToTop);
                    }
                },
                setCategoryLink: function (category) {
                    this.previousCategoryLink = this.categoryLink;
                    this.categoryLink = category;
                },
                setLink: function (link, category) {
                    if (category) {
                        // GTM - GA4
                        this.pushToGTM(category);
                    }
                    if (this.isLink(link)) {
                        try {
                            // re-direct to category link
                            window.location.assign(link);
                        } catch (error) {
                            console.error(error.message);
                        }
                    }
                },
                setNav: function (destinationName, destinationUrl, navType) {
                    switch (navType) {
                        case "primary":
                            this.originName = '';
                            this.originUrl = '#';
                            if (this.isLink(destinationUrl)) {
                                this.setLink(destinationUrl);
                            }
                            this.scrollToTop();
                            break;
                        case "secondary":
                            if (this.isLink(destinationUrl)) {
                                this.closeMobileNav();
                                this.setLink(destinationUrl);
                            }
                            this.setCategoryLink(this.nav[destinationName].categoryUrl);
                            this.originName = this.nav[destinationName].primary;
                            this.originUrl = this.nav[destinationName].link;
                            this.previousNav = this.nav;
                            this.nav = navigation.primary[destinationName].secondary;
                            this.switchMenu('secondary');
                            this.previousNavType = 'primary';
                            this.navType = navType;
                            this.targetIndex = destinationName;
                            this.hasNestedLinks(this.nav, navType);
                            this.scrollToTop();
                            // GTM - GA4
                            this.pushToGTM(this.originName.replace('/\\n/g', '').trim());
                            break;
                        case "tertiary":
                            this.setCategoryLink(this.nav[destinationName].categoryUrl);
                            this.originName = this.nav[destinationName].secondary;
                            this.originUrl = this.nav[destinationName].link;
                            this.previousNav = this.nav;
                            this.nav = this.nav[destinationName].tertiary;
                            this.switchMenu('tertiary');
                            this.navType = navType;
                            if (!this.nav.length) {
                                if (this.isLink(destinationUrl)) {
                                    this.closeMobileNav();
                                    this.setLink(destinationUrl);
                                }
                            }
                            this.scrollToTop();
                            // GTM - GA4
                            this.pushToGTM(this.originName);
                            break;
                        default:
                            this.previousNavType = 'secondary';
                    }
                },
                switchMenu: function (menuId) {
                    var menuId2 = '#mobile-' + menuId;

                    if (this.menuId !== menuId2) {
                        $(this.menuId).hide();
                        this.menuId = menuId2;
                        $(menuId2).show().addClass('animated slideInLeft');
                    }
                    if (menuId2 === '#mobile-primary') {
                        this.isRootNav = true;
                    } else {
                        this.isRootNav = false;
                    }
                }
            }
        });

        function categoryClickHandler(event, encodedCategoryName, categoryUrl) {
            // cancel href
            event.preventDefault();

            // GTM - GA4
            pushToGTM(encodedCategoryName);

            if (categoryUrl) {
                try {
                    // re-direct to category page
                    window.location.assign(categoryUrl);
                } catch (error) {
                    console.error(error.message);
                }
            }
        }

        function init() {
            var chevron = "›";
            var activeBrandLink = $('a[data-brand-id="' + brandIdentifier + '"]');
            var i = $(activeBrandLink.children()[1]);

            // menu highlight
            $('.dropdown-menu').mouseover(function () {
                var over = $(this).closest('.nav-root-category').find('.dropdown-toggle');

                over.trigger('mouseover');
            }).mouseout(function () {
                var out = $(this).closest('.nav-root-category').find('.dropdown-toggle');

                out.trigger('mouseout');
            });

            //get navigation
            $("nav").each(function (n) {
                $(this).children().find('.nav-root-category').each(function (x) {
                    //get top level navigation
                    var primary = $(this).children().closest('a').text().trim();

                    if (primary.indexOf(chevron) !== -1) {
                        primary = primary.trim().slice(0, -1);
                    }
                    navigation.primary.push({
                        "primary": primary,
                        "link": $(this).children().closest('a').attr('href').trim(),
                        "children": false,
                        "categoryUrl": $(this).children().data('url'),
                        "backgroundColor": $(this).children().css("background-color"),
                        "textColor": $(this).children().css("color")
                    });
                    //get second level navigation
                    navigation.primary[x].secondary = [];
                    $(this).children().find('.nav-sub-category').each(function (y) {
                        navigation.primary[x].secondary.push({
                            "secondary": $(this).children().closest('a').text().trim(),
                            "link": $(this).children().closest('a').attr('href').trim(),
                            "children": false,
                            "categoryUrl": $(this).children().closest('a').attr('href').trim()
                        });
                        //get third level navigation
                        navigation.primary[x].secondary[y].tertiary = [];
                        $(this).children().find('.nav-sub-sub-category').each(function () {
                            navigation.primary[x].secondary[y].tertiary.push({
                                "tertiary": $(this).children().closest('a').text().trim(),
                                "link": $(this).children().closest('a').attr('href').trim(),
                                "children": false
                            });
                        });
                    });
                });

                $(this).children().find('.navbar-link:not("first-child")').each(function () {
                    navbar.push({
                        "primary": $(this).find('a').text(),
                        "link": $(this).find('a').attr('href').trim()
                    });
                });

                menus[$(this).data("id")] = {
                    navigation: navigation,
                    navbar: navbar
                };
                navigation = {
                    primary: []
                };
                navbar = [];
            });

            $(".site-menu").on("click", function (e) {
                var target = $(e.currentTarget);
                var siteMenu = target.data("siteMenu");
                var remainingUrl = target.data("zone");
                var j;

                e.preventDefault();

                brandBackgroundColor = target.css("background-color");
                navigation = menus[siteMenu].navigation;
                navbar = menus[siteMenu].navbar;
                vm.nav = menus[siteMenu].navigation.primary;
                // Add remainingUrl (with "zone" parameter) to "Shop the Brand" (brand landing page) link
                if (remainingUrl && vm.nav[0].link.indexOf(remainingUrl) === -1) vm.nav[0].link += remainingUrl;
                vm.navBar = menus[siteMenu].navbar;
                vm.brandBackgroundColor = brandBackgroundColor;
                $("#mobile-menu").insertAfter(target);
                vm.scrollToTop();
                vm.resetMenu();
                $(".site-menu i").each(function () {
                    $(this).removeClass("fa-chevron-up").addClass("fa-chevron-down");
                });
                j = $(target.children()[1]);
                j.removeClass("fa-chevron-down").addClass("fa-chevron-up");
            });

            $("#mobile-menu").insertAfter(activeBrandLink);
            navigation = menus[activeBrandLink.data("siteMenu")].navigation;
            navbar = menus[activeBrandLink.data("siteMenu")].navbar;
            brandBackgroundColor = activeBrandLink.css("background-color");
            i.removeClass("fa-chevron-down").addClass("fa-chevron-up");

            menuEvents();
        }

        function lightenDarkenColor (col, amt) {
            var usePound = false;
            var num, r, b, g;

            if (col[0] === "#") {
                col = col.slice(1);
                usePound = true;
            }
            num = parseInt(col, 16);
            r = (num >> 16) + amt;
            if (r > 255) r = 255;
            else if (r < 0) r = 0;
            b = ((num >> 8) & 0x00FF) + amt;
            if (b > 255) b = 255;
            else if (b < 0) b = 0;
            g = (num & 0x0000FF) + amt;
            if (g > 255) g = 255;
            else if (g < 0) g = 0;
            return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
        }

        function menuEvents () {
            var nav = $('#mobile-nav');
            var menu = $('.navbar-header').find('button');
            var background = $('#mobile-background');
            var toggleMenu = function () {
                if (menu.hasClass('collapsed')) {
                    nav.show().addClass('animated slideInLeft');
                    background.show();
                } else {
                    nav.hide();
                    background.hide();
                }
            };
            var collapseMenu = function () {
                $(nav).hide();
                $(background).hide();
                $(menu).addClass('collapsed');
                vm.resetMenu();
            };

            menu.on('click', function () {
                toggleMenu();
            });

            background.on('click', function () {
                collapseMenu();
            });
        }

        
        function pushToGTM(encodedCategoryName) {
            if (encodedCategoryName) {
                // GTM - GA4
                cei.shared.tagmanager.addToGA4DataLayer({
                    'event': 'category_selected',
                    'browse_category': decodeURIComponent(encodedCategoryName)
                });
            }
        }

        init();

        return {
            categoryClickHandler: categoryClickHandler,
            lightenDarkenColor: lightenDarkenColor,
            pushToGTM: pushToGTM,
            vm: vm
        };
    }
})(window.jQuery);
