/// <reference path="cei.shared.hawksearch.js" />
cei.shared.headersearch = (function ($) {

    /**
     * @description Document Ready Handler Function
     */
    $(function () {

        const clearSearchIcon = $('#clear-search');
        const searchLogoButton = $('#search-logo-button');
        const headerSearchForm = $('#header-search-form');
        const headerSearchBox = $('#header-search-box');

        /**
         * @description Handles the search form submission
         * @param {object} event -- The form submission event
         */
        function submitHandler(event) {
            event.preventDefault();

            let searchTermValue = headerSearchBox.length ? headerSearchBox.val().replace('&', 'and').trim() : '';

            if (searchTermValue) {
                if (headerSearchForm.length) {
                    // check for alias in search term
                    let aliasRegex = /^#?\d{3}(-|\s)?\d{2}(-|\s)?[\da-zA-Z]{5}$/;
                    let aliasTestResult = aliasRegex.test(searchTermValue);
                    let redirectUrl;
                    let pdpRedirectIndex = 0;

                    // set brand indentifier and search term (to be used on category and search) to browser's local storage
                    window.localStorage.setItem('BrandIdentifier', window.brandIdentifier);
                    window.localStorage.setItem('SearchTerm', searchTermValue);
                    
                    // make SearchByText API Call
                    $.ajax({
                        method: 'POST',
                        url: '/api/Search/SearchByText',
                        data: JSON.stringify({
                            Terms: searchTermValue,
                            Brand: window.brandIdentifier
                        }),
                        contentType: 'application/json; charset=utf-8',
                        dataType: 'json'
                    })
                    .then(function (result) {
                        let searchType, linkTarget;

                        if (result?.Redirect?.Url) {
                            redirectUrl = result.Redirect.Url;
                            linkTarget = result.Redirect.Target;

                            if (!linkTarget) {
                                // Category Redirect
                                searchType = 'category';
                            } else {
                                // External Link to Open New Window
                                searchType = 'external';
                            }
                        } else if (result?.Products?.length === 1) {
                            // Product Redirect
                            let product = result.Products[pdpRedirectIndex] || '';
                            let itemNumber = product.ItemNumber || '';
                            let trackingId = result.TrackingId || '';
                            redirectUrl = product.Url || '';
                            searchType = 'product';

                            let hawksearchClickEventObject = {
                                'event': 'hawksearchClick',
                                'trackingId': trackingId,
                                'index': pdpRedirectIndex,
                                'uniqueId': itemNumber
                            };

                            // Store the HawkSearchEventObject in Local Storage to be accessed on PDP page load
                            window.localStorage.setItem('HawksearchClickEventObject', JSON.stringify(hawksearchClickEventObject));
                        } else {
                            // Search Redirect
                            redirectUrl = '/search';
                            searchType = 'search';
                            // Remove saved category & filters
                            window.localStorage.removeItem('Category');
                            window.localStorage.removeItem('Refinements');
                        }

                        // Add searchtype to localstorage for use on category and search pages
                        window.localStorage.setItem('SearchType', searchType);

                        if (!aliasTestResult) {
                            // append 'ntt' parametered querystring to non-alias redirect urls
                            redirectUrl += `?ntt=${window.encodeURIComponent(searchTermValue)}`;
                        }

                        // GTM/GA4 Search Event Tracking
                        cei.shared.tagmanager.addToGA4DataLayer({
                            'event': 'search',
                            'search_term': searchTermValue ? searchTermValue : undefined,
                            'search_type': searchType ? searchType : undefined,
                            'search_filter': undefined
                        });

                        // Redirect
                        try {
                            if (searchType !== 'external') {
                                window.location.assign(redirectUrl);
                            } else {
                                window.open(redirectUrl, linkTarget);
                            }
                        } catch (error) {
                            console.error(error.message);
                        }

                    }).catch(function (jqXHR) {
                        // ERROR FUNCTION
                        if (jqXHR.status === 0) {
                            console.error(jqXHR.status);
                            $.ajax(this);

                            return;
                        } else if (jqXHR.status === 422) { //custom status code results in error returning, but this is success
                            if (result?.Products?.length === 1) {
                                // Product Redirect
                                let product = result.Products[pdpRedirectIndex];
                                let itemNumber = product.ItemNumber;
                                let trackingId = result.TrackingId;
                                redirectUrl = product.Url;
                                searchType = 'product';
                            
                                // GTM - HawkSearch Click Event
                                cei.shared.tagmanager.addToDataLayer({
                                    'event': 'hawksearchClick',
                                    'trackingId': trackingId,
                                    'index': pdpRedirectIndex,
                                    'uniqueId': itemNumber ? itemNumber : ''
                                });
                            } else {
                                // Search Redirect
                                redirectUrl = '/search';
                            }

                            if (!aliasTestResult) {
                                // append 'ntt' parametered querystring to non-alias redirect urls
                                redirectUrl += `?ntt=${window.encodeURIComponent(searchTermValue)}`;
                            }
                                
                            // Redirect
                            try {
                                window.location.assign(redirectUrl);
                            } catch (e) {
                                console.error(e.message);
                            }
                        } else {
                            console.error(`${jqXHR.status}: no search results were returned.`);
                        }
                    });
                }
            } else {
                console.error('Please enter a Keyword or an Item #.');
            }
        }

        /**
         * @description Handles the search icon click event for Mobile
         */
        function searchIconClickHandler() {
            let containerSearch = $('#container-search'), branding = $('#branding');

            if (containerSearch.length) {
                containerSearch.toggleClass('hidden-xs');
            }
            if (branding.length) {
                branding.toggleClass('hidden-search');
            }
        }

        // Add and remove an X icon in the header search bar when clicked inside

        clearSearchIcon.toggle(Boolean(headerSearchBox.val()));

        // Event Handlers

        // Clear Search Icon
        headerSearchBox.on('keyup', function () {
            clearSearchIcon.toggle(Boolean($(this).val()));
        });
        clearSearchIcon.on('click', function () {
            $(this).hide();
            headerSearchBox.val('').focus();
        });

        //// Search Form
        if (headerSearchForm.length) {
            headerSearchForm.on('submit', submitHandler);
        }

        // Search Logo (mobile)
        if (searchLogoButton.length) {
            searchLogoButton.on('click', searchIconClickHandler);
        }
    });

})(window.jQuery);