cei.shared.cookies = (function(document) {
    /**
     * @description create cookie
     * @param {string} name - cookie name
     * @param {string} value - cookie value
     * @param {number} days - days until cookie expires
     * @example cei.shared.cookies.createCookie('cookieName', 'cookieValue', 365);
     */
    function createCookie(name, value, days) {
        var date,
            expires;

        if (days) {
            date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = ";expires=" + date.toUTCString();
        } else {
            expires = "";
        }
        document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    }
    /**
     * @description Create Processing Fee (PF) Cookie
     * @param {string} cookieName - cookie name
     * @param {number} expirationInDays - days until cookie expires
     * @example cei.shared.cookies.createPFCookie('cookieName', 7);
     */
    function createPFCookie(cookieName, expirationInDays) {
        if (!cookieName && !expirationInDays) return;

        let processingFeeArray = [];

        // create a processing fee event objects array from the datalayer
        window.dataLayer.forEach(obj => {
            if (obj.event === cookieName && obj[cookieName]) {
                processingFeeArray.push(obj);
            }
        });
        
        // if the processing fee event objects array is not empty, then create a cookie from the last (latest) object
        let processingFeeArrayLength = processingFeeArray.length;
        if (processingFeeArrayLength) {
            createCookie(cookieName, processingFeeArray[processingFeeArrayLength - 1][cookieName], expirationInDays);
        }
    }
    /**
     * @description erase cookie
     * @param {string} name - cookie name
     * @example cei.shared.cookies.eraseCookie('cookieName');
     */
    function eraseCookie(name) {
        createCookie(name, "", -1);
    }
    /**
     * @description read cookie
     * @param {string} name - cookie name
     * @returns {string, null} decoded cookie string or null 
     * @example cei.shared.cookies.readCookie('cookieName');
     */
    function readCookie(name) {
        var nameEQ = encodeURIComponent(name) + "=";
        var ca = document.cookie.split(';');
        for (var i = 0, j = ca.length; i < j; i++) {
            while (ca[i].charAt(0) === ' ') ca[i] = ca[i].substring(1, ca[i].length);
            if (ca[i].indexOf(nameEQ) === 0) return decodeURIComponent(ca[i].substring(nameEQ.length, ca[i].length));
        }
        return null;
    }

    return {
        createCookie: createCookie,
        createPFCookie: createPFCookie,
        eraseCookie: eraseCookie,
        readCookie: readCookie
    };

})(window.document);
