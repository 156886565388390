cei.shared.utilities = (function () {

    function date(dateString) {
        var date = new Date(dateString);
        var m = date.getMonth() + 1;
        var d = date.getDate();
        var y = date.getFullYear();

        return m + "/" + d + "/" + y;
    }

    function round(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    return {
        date: date,
        round: round
    };

})();