cei.shared.footerad = (function () {
    return {
        showFSAds: false,
        showAds: function () {
            this.showFSAds = true;
        },
        hideAds: function () {
            this.showFSAds = false;
        }
    };
})();