cei.shared.ajax.jquery = (function ($) {

    var defaults = {
        cache: false, // page not cached by browser (for get requests)
        contentType: 'application/json; charset=utf-8', // content type of data sent to server
        data: null, // data sent to server (a.k.a, payload)
        dataType: 'json', // data type received from server
        method: 'GET', // default HTTP method
        timeout: 0, // no timeout (milliseconds)
        headers: null // no headers
    };

    /**
     * @description makes a jquery ajax request
     * @example cei.shared.ajax.jquery.makeRequest({
     *              method: 'POST',
     *              url: '/api/CartItem',
     *              data: {SkuCode: 'Sku_42707_BURG', Quantity: 1, Alias: null},
     *              headers: { "__RequestVerificationToken": $("input[name='__RequestVerificationToken']").val() }
     *          });
     * @param {object} options - options object
     * @returns {object} jqXHR Object
     */
    function makeRequest(options) {
        var settings = $.extend({}, defaults, options);

        if (!settings.url) return;

        return $.ajax({
            cache: settings.cache,
            contentType: settings.contentType,
            data: JSON.stringify(settings.data),
            dataType: settings.dataType,
            method: settings.method,
            timeout: settings.timeout,
            url: settings.url,
            headers: settings.headers
        });
    }

    return {
        makeRequest: makeRequest
    };

})(window.jQuery);
